import logo from "./logo.svg";
import "./App.css";
import { useState, useEffect } from "react";
import { GoogleOAuthProvider, GoogleLogin, googleLogout } from "@react-oauth/google";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Ratio from "react-bootstrap/Ratio";
import Wrapper from "./components/Wrapper";
import Card from "react-bootstrap/Card";
import LoginForm from "./components/LoginForm";

function App() {
  const [googleApiKeys, setGoogleApiKeys] = useState({});
  const [googleCredentials, setGoogleCredentials] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    fetch("/api/apikeys/googleoauthkeys")
      .then((response) => response.json())
      .then((data) => {
        setGoogleApiKeys(data.data.googleApiKeys);
        console.log(data.data.googleApiKeys);
      })
      .catch((err) => console.log(err));

    fetch("/api/home/index")
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // when the user successfully login , google credentials variable state will change, then
  // useEffect to do a POST call to the server

  useEffect(() => {
    if (googleCredentials.clientId) {
      fetch("/api/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(googleCredentials),
      })
        .then((res) => res.json())
        .then((data) => console.log(data))
        .catch((err) => console.log(err));
    }
  }, [googleCredentials]);

  const handleClick = () => {
    // fetch("/api/home/index")
    //   .then((response) => response.json())
    //   .then((data) => console.log(data));
  };

  const handleSuccessLogin = (credentialResponse) => {
    console.log(credentialResponse);
    setGoogleCredentials(credentialResponse);
    setIsLoggedIn(true);
  };

  const handleFailLogin = () => {
    console.log("login failed");
  };

  return (
    <Wrapper>
      <Wrapper className="page-holder page-holder align-items-center py-4 bg-gray-100 vh-100">
        <Container>
          <Row>
            <Col xs={6}>
              <Card>
                <Card.Header className="text-primary bg-white card-heading p-4">
                  Track n Pay
                </Card.Header>
                <Card.Body className="">
                  <Ratio aspectRatio={1 / 2}>
                    <img alt="Login" src="img/login.svg" width={"100%"} height={"100%"}></img>
                  </Ratio>
                  {!isLoggedIn && <LoginForm />}
                  <div className="d-flex align-items-center justify-content-center">
                    <GoogleOAuthProvider clientId={googleApiKeys.clientId}>
                      {!isLoggedIn && (
                        <GoogleLogin
                          onSuccess={(credentialResponse) => handleSuccessLogin(credentialResponse)}
                          onError={handleFailLogin}
                          size="medium"
                          width={"400px"}
                        />
                      )}
                    </GoogleOAuthProvider>
                  </div>
                  {isLoggedIn && <p>You are Logged in!</p>}
                </Card.Body>
              </Card>
            </Col>
            <Col xs={6} className="py-2">
              <div className="mb-2">
                <h1 className="display-1 bg-primary rounded d-inline text-light px-3">
                  <strong>We track</strong>
                </h1>
              </div>
              <div className="mb-4">
                <h2 className="px-3">So you can pay</h2>
              </div>
              <Ratio aspectRatio="16x9">
                <img
                  alt="People doing some tech stuff"
                  src="img/home.svg"
                  width={"100%"}
                  height={"100%"}
                ></img>
              </Ratio>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    </Wrapper>
  );
}

export default App;
