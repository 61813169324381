const Wrapper = (props) => {
  const { className, children, ...rest } = props;
  const classNames = className ?? `d-flex align-items-stretch `;
  return (
    <div className={classNames} {...rest}>
      {children}
    </div>
  );
};

export default Wrapper;
